<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="sub-modal">
      <div class="row mb-3">
        <div class="col-12">
          <modal-actions @close="$router.push({ name: 'fdr' })" />
        </div>
      </div>

      <div class="row p-0 m-0 mb-3 shdow p-3">
        <div class="col-12 d-flex flex-wrap align-items-center justify-content-between">
          <p class="text-secondary h1 mb-0">
            <span>Information générales</span>
            <!-- <span>Feuille n°1</span> -->
          </p>

          <b-button class="mt-2" variant="secondary">Confirmer</b-button>
        </div>

        <div class="col-12">
          <hr class="border my-3" />
        </div>

        <div class="col-12" style="flex: 1; margin-right: 20px">
          <b-form class="row">
            <div class="col-md-6 col-12">
              <b-form-group label="Libellé:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Libellé "
                  value="Libellé 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Entité:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Entité"
                  value="Entité 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Date de création:"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-datepicker disabled :value="new Date()"></b-form-datepicker>
              </b-form-group>

              <b-form-group label="Organisation:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Organisation"
                  value="Organisation 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Patrimoine:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Patrimoine"
                  value="Patrimoine 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Responsable finance:"
                label-cols-sm="3"
                label-align-sm="left"
              >
                <b-form-input
                  type="text"
                  placeholder="Responsable finance"
                  value="Responsable 1"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-6 col-12">
              <b-form-group label="Responsable:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Responsable"
                  value="Responsable 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Type FdR:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Type FdR"
                  value="Fdr 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Statut:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Statut"
                  value="En cours"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Échéances:" label-cols-sm="3" label-align-sm="left">
                <b-form-input
                  type="text"
                  placeholder="Échéances"
                  value="échéance 1"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Date début:" label-cols-sm="3" label-align-sm="left">
                <!-- <b-form-input
                  type="datetime-local"
                  placeholder="Date début"
                  :value="new Date()"
                  required
                ></b-form-input> -->
                <b-form-datepicker></b-form-datepicker>
              </b-form-group>

              <b-form-group label="Date fin:" label-cols-sm="3" label-align-sm="left">
                <!-- <b-form-input
                  type="datetime-local"
                  placeholder="Date fin"
                  :value="new Date()"
                  required
                ></b-form-input> -->
                <b-form-datepicker></b-form-datepicker>
              </b-form-group>
            </div>
          </b-form>
        </div>
      </div>
      <b-tabs class="tab-solid tab-solid-primary mt-5">
        <b-tab title="Finance" active lazy class="my-2">
          <div class="row p-0 m-0 flex-wrap">
            <div class="col-sm-6 pl-sm-0 d-flex flex-column justify-content-between mb-3">
              <div>
                <div class="hdr py-2 text-white rounded mb-3">
                  Situation actuelle: 10/11/2021
                </div>
                <p>
                  <span class="spn">Budget des initiatives:</span>
                  8000€
                </p>
                <p>
                  <span class="spn">Budget FdR:</span>
                  8000€
                </p>
                <p>
                  <span class="spn">Ecart budget:</span
                  ><span style="color: #1dbf4d; font-weight: bold"> 2000€</span>
                </p>
              </div>
              <div>
                <p>
                  <span class="spn">Dernier ajustement:</span>
                  10/11/2021
                </p>
                <div class="row m-0 p-0 align-items-center">
                  <span class="mr-2">Ajustement</span>

                  <b-form-input
                    v-model="text"
                    placeholder="Ajuster"
                    class="w-25 mr-2 border border-gray"
                    size="sm"
                  ></b-form-input>

                  <b-button size="sm" class="bg-blue" variant="info">Réviser</b-button>
                </div>
              </div>
            </div>
            <div class="col-sm-6 pr-sm-0">
              <div class="hdr py-2 text-white rounded mb-3">Suivi conso:</div>
              <PieChart style="width: 100%" :options="pieOptions" />
              <p>Avancement:</p>
              <div
                style="
                  margin-top: 15px;
                  background: #ededed;
                  height: 20px;
                  width: 100%;
                  position: relative;
                  border-radius: 20px;
                  overflow: hidden;
                "
              >
                <div
                  style="
                    width: 90%;
                    height: 100%;
                    background: #1dbf4d;
                    position: absolute;
                    top: 0;
                  "
                ></div>
                <p
                  style="
                    position: relative;
                    color: black;
                    font-size: 15px;
                    text-align: center;
                  "
                >
                  90%
                </p>
              </div>
            </div>
          </div>

          <div class="hdr py-2 text-white rounded mt-4 mb-3">Historique</div>

          <b-table
            :items="financeHistory.tableItems"
            :fields="financeHistory.tableFields"
            responsive="sm"
          >
            <template v-slot:cell(resp_fin)="data">
              <div>
                <b-avatar
                  v-b-tooltip.hover="data.value.full_name"
                  variant="primary"
                  size="2rem"
                  :src="data.value.img"
                  class="mr-1"
                ></b-avatar>
                {{ data.value.name }}
              </div>
            </template>
            <template #cell(tendance)="data">
              <div class="p-0 m-0 row">
                <i style="font-size: 20px; color: red" class="mdi mdi-menu-down"></i>
                <p>{{ data.value }}</p>
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Enjeux stratégiques">
          <StrategicChallenge
            :strategicChallenge="strategicChallenge"
            @cancelItem="(itemIndex) => removeStrategyChallengeInputs(itemIndex)"
          />
        </b-tab>
        <b-tab title="Initiatives">
          <Initiatives :initiatives="initiatives" />
        </b-tab>
        <b-tab title="Plan de transformation">
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <dropdown-selection
              class="w-25"
              :text="selectedTransformationInitiative.text"
              :items="transformationInitiativeOptions"
              @seleted="(item) => (selectedTransformationInitiative = item)"
            ></dropdown-selection>
            <dropdown-selection
              class="w-25"
              :text="selectedTransformationApplication.text"
              :items="transformationApplicationOptions"
              @seleted="(item) => (selectedTransformationInitiative = item)"
            ></dropdown-selection>
            <a href="#"><i class="mdi mdi-printer" style="font-size: 30px"></i></a>
          </div>
          <TransformationPlan />
        </b-tab>
        <b-tab title="Comité et décision">
          <CommitteeDecision
            :tableItems="comiteDecision.tableItems"
            @searchGuests="searchGuestsModal = !searchGuestsModal"
          />
        </b-tab>
      </b-tabs>
    </div>

    <!-- Add new initiative -->
    <div to="modals-xyz-548" v-show="showAddInitiative">
      <Modal @close="showAddInitiative = !showAddInitiative">
        <add-new-initiative />
      </Modal>
    </div>

    <!-- Search guests -->
    <div to="modals-xyz-548" v-show="searchGuestsModal">
      <Modal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests @close="searchGuestsModal = !searchGuestsModal" />
      </Modal>
    </div>
  </div>
</template>

<script>
import PieChart from "@/components/charts/c3-chart/donut-c3.vue";
// import TableActions from "@/components/custom/TableActions.vue";

import DropdownSelection from "@/components/custom/DropdownSelection.vue";

import Modal from "@/components/custom/Modal.vue";
import AddNewInitiative from "./components/AddNewInitiative.vue";
import SearchGuests from "./components/SearchGuests.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Initiatives from "./components/Initiatives.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

import TransformationPlan from "@/components/custom/TransformationPlan.vue";

export default {
  name: "AddFdr",
  components: {
    PieChart,
    // TableActions,
    DropdownSelection,
    Modal,
    AddNewInitiative,
    SearchGuests,
    CommitteeDecision,
    StrategicChallenge,
    Initiatives,
    ModalActions,
    TransformationPlan,
  },
  data: () => ({
    text: "",
    comiteDecision: {
      viewOptions: [
        { value: "tableau", text: "Tableau" },
        { value: "gantt", text: "Gantt" },
      ],
      selectedView: { value: "tableau", text: "Tableau" },

      tableItems: [
        {
          committee: "",
          committee_date: "",
          object_path: "",
          presentator: "",
          inscription_date: "",
          guests: "S. Ewore, J. page",
          delivery: "Dossier Architecture",
          decision: "Ajourné",
          decision_comment: "",
          actions: ["show", "edit", "delete", "transfer"],
        },
      ],
    },
    financeHistory: {
      tableItems: [],
      tableFields: [],
    },
    strategicChallenge: {
      tableItems: [{ editMode: true }],
    },
    initiatives: {
      tableItems: [
        {
          ref: "INIT-010203",
          label: "Transfo CRM",
          responsable: {
            name: "S.E",
            full_name: "Steve Ewore",
            img: "https://source.unsplash.com/random/150x150",
          },
          deadline: { start_date: "10/01/2021", end_date: "10/12/2021" },
          status: "Proposé",
          project_phase: "Production",
          object_type: "Application",
          main_object: "CRO Salesforce",
          progress: 90,
          actions: ["show", "delete", "transfer"],
        },
      ],
    },
    transformationInitiativeOptions: [{ value: null, text: "Toutes" }],
    transformationApplicationOptions: [{ value: null, text: "Applications" }],
    selectedTransformationInitiative: { text: "Toutes", value: null },
    selectedTransformationApplication: { text: "Application", value: null },
    searchGuestsModal: false,
    pieOptions: {
      data: {
        columns: [
          ["Consommé", 6000],
          ["Reste", 4000],
        ],
        type: "donut",
      },
      color: {
        pattern: ["#FFC000", "#072648"],
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0,
      },
      bar: {
        width: {
          ratio: 0.7, // this makes bar width 50% of length between ticks
        },
      },
    },
    showAddInitiative: false,
  }),
  methods: {
    addStrategyChallengeInputs() {
      this.strategicChallenge.tableItems = [
        ...this.strategicChallenge.tableItems,
        {
          vision: null,
          strategic_goal: null,
          description: null,
          responsable: null,
          entity: null,
          organisation: null,
          editMode: true,
        },
      ];
    },
    removeStrategyChallengeInputs(index) {
      this.strategicChallenge.tableItems = this.strategicChallenge.tableItems.filter(
        (item, itemIndex) => itemIndex !== index
      );
    },
  },
};
</script>

<style scoped>
.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    /* transform: translateY(-50%) !important; */
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: center; */
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  /* border-radius: 10px; */
  /* min-width: 950px;
  max-width: 950px;
  max-height: 80%; */
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>
